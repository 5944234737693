// https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#62
const wxURL = '//res.wx.qq.com/open/js/jweixin-1.6.0.js';
import request from './request/next-request';
import { src } from './common';
import { captureException } from '@sentry/nextjs';

declare var wx: any;
const WxErrorName: string = 'WechatShareError';

const SHARE_TICKET = 'STREAMLAKE_SHARE_TICKET';

interface IShareData {
  title: string; // 分享标题
  desc: string; // 分享描述
  link?: string; // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
  imgUrl?: string; // 分享图标
}

interface IShareConfig {
  debug: boolean; // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
  appId: string; // 必填，公众号的唯一标识
  timestamp: number; // 必填，生成签名的时间戳
  nonceStr: string; // 必填，生成签名的随机串
  signature: string; // 必填，签名
  jsApiList: string[]; // 必填，需要使用的 JS 接口列表
}

interface IShareCallback {
  success?: Function; // 接口调用成功时执行的回调函数。
  fail?: Function; // 接口调用失败时执行的回调函数。
  complete?: Function; //接口调用完成时执行的回调函数，无论成功或失败都会执行。
  cancel?: Function; //用户点击取消时的回调函数，仅部分有用户取消操作的 api 才会用到。
  trigger?: Function; //监听 Menu 中的按钮点击时触发的方法，该方法仅支持 Menu 中的相关接口。
}

const defaultData: IShareData = {
  title: 'StreamLake',
  desc: '视频化升级助推器',
  link: '',
  imgUrl: src('/share.png'),
};

const defaultConfig: Pick<IShareConfig, 'debug' | 'jsApiList'> = {
  debug: false,
  jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
};

const defaultCallback: IShareCallback = {
  success: (res) => {
    console.log(res);
  },
  fail: (err) => {
    console.log(err);
    const e = new Error(err.errMsg);
    e.name = WxErrorName;
    captureException(e);
  },
};

class WechatShare {
  private p: Promise<void>;
  constructor({ debug = false }: { debug?: boolean } = {}) {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production' && !debug) {
      console.info(
        '[wechat-share] WechatShare is not working in this environment with debug mode off.'
      );
      return;
    }
    this.init();
  }
  private loadScript() {
    const script = document.createElement('script');
    this.p = new Promise((resolve) => {
      script.onload = () => {
        resolve();
      };
    });
    script.src = wxURL || '';
    document.body.appendChild(script);
  }
  private async init() {
    this.loadScript();
    try {
      await this.getWechatTicket();
    } catch (err) {
      captureException(err);
    }
  }

  async updateConfig({ title, desc }: Partial<IShareData>) {
    if (!this.p) return;
    const data = {
      title:
        title || document.querySelector('title')?.innerText || 'StreamLake',
      desc:
        desc ||
        (document.querySelector('meta[name="description"]') as HTMLMetaElement)
          ?.content ||
        '',
      link: location.href,
    };
    this.p.then(async () => {
      try {
        const { ticket } = await this.getWechatTicket();
        const res = await this.getWechaSignature(ticket, data.link);
        const shareData: IShareData = Object.assign({}, defaultData, data);
        const shareConfig: IShareConfig = Object.assign({}, defaultConfig, res);
        wx?.config(shareConfig);
        wx?.ready(() => {
          wx.updateAppMessageShareData({ ...shareData, ...defaultCallback });
          wx.updateTimelineShareData({ ...shareData, ...defaultCallback });
        });
      } catch (err) {
        console.error(err);
        captureException(err);
      }
    });
  }

  private async getWechatTicket(): Promise<{ ticket: string; expire: number }> {
    const cache = JSON.parse(localStorage.getItem(SHARE_TICKET));
    if (!cache || Math.round(new Date().getTime() / 1000) > cache.expire) {
      const res = await request({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/wechat`,
        method: 'get',
        data: { action: 'getTicket' },
      });
      if (res) {
        localStorage.setItem(SHARE_TICKET, JSON.stringify(res));
        return res;
      } else {
        const e = new Error('get wechat jsapi ticket failed');
        e.name = WxErrorName;
        throw e;
      }
    }
    return cache;
  }

  private async getWechaSignature(
    ticket: string,
    url: string
  ): Promise<Omit<IShareConfig, 'jsApiList' | 'debug'>> {
    const res = await request({
      url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/wechat`,
      method: 'get',
      data: {
        action: 'getSignature',
        ticket,
        url,
      },
    });
    return res;
  }
}

export default WechatShare;
