'use client';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default function myImageLoader({ src, width, quality }) {
  return `${publicRuntimeConfig.publicFolderPath}${src}?w=${width}&q=${
    quality || 75
  }`;
}
