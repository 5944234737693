import { HEADER_CONFIG, HOME_PAGE_CONFIG, RouterConfig } from './const/router';

type TDKConfig = {
  [path: string]: {
    title: string;
    customTitle?: string;
    description: string;
    keywords: string;
  };
};

const transformConfig = (config: RouterConfig[]): TDKConfig => {
  const tdkConfig: TDKConfig = {};

  const traverseMenu = (menu: RouterConfig[] | RouterConfig['children'][]) => {
    menu.forEach((item) => {
      if (item.link) {
        tdkConfig[item.link] = {
          title: item.title,
          customTitle: item.customTitle,
          description: item.description || '',
          keywords: item.keywords || '',
        };
      }

      if (item.children) {
        traverseMenu(item.children);
      }

      if (item.items) {
        traverseMenu(item.items);
      }
    });
  };

  traverseMenu(config);

  return tdkConfig;
};

const tdkConfig = transformConfig([HOME_PAGE_CONFIG, ...HEADER_CONFIG]);

export default tdkConfig;
