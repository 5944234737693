import { src } from '@/utils/common';
import NextHead from 'next/head';
import type { PropsWithChildren } from 'react';
import { HOME_PAGE_CONFIG } from '@/utils/const/router';

type HeadProps = PropsWithChildren<{
  title?: string;
  description?: string;
  keywords?: string;
  customTitle?: string;
  canonicalUrl?: string;
}>;

const BASE_TITLE = 'StreamLake';
const BASE_DESCRIPTION = HOME_PAGE_CONFIG.description;
const BASE_KEYWORDS = HOME_PAGE_CONFIG.keywords;

const Head = ({
  title,
  description,
  keywords,
  customTitle,
  children,
  canonicalUrl,
}: HeadProps) => {
  const _title = customTitle || (title ? `${title}-${BASE_TITLE}` : BASE_TITLE);
  return (
    <NextHead>
      <title>{_title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
      ></meta>
      <meta name="baidu-site-verification" content="code-DeLQbpkF0K" />
      <meta
        name="google-site-verification"
        content="CL7n18W_1soNyTvAjAAqGSC6TMEi9pP0sZE7yRVkkqg"
      />
      <link rel="icon" href={src('/favicon.ico')} />
      <meta name="description" content={description || BASE_DESCRIPTION} />
      <meta name="keywords" content={keywords || BASE_KEYWORDS} />
      {canonicalUrl && (
        <>
          <link rel="canonical" href={canonicalUrl} />
          <meta property="og:url" content={canonicalUrl} />
        </>
      )}

      <meta property="og:title" content={_title} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={src('/dist/img/StreamLake_1200-630.jpg')}
      />
      <meta property="og:site_name" content={BASE_TITLE} />
      <meta
        property="og:description"
        content={description === '' ? BASE_DESCRIPTION : description}
      />
      {children}
    </NextHead>
  );
};

export default Head;
