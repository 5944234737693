/* eslint-disable @next/next/no-img-element */
import styles from './styles/layout.module.scss';
import Header from './layoutComponents/header';
import Footer from './layoutComponents/footer';
import Empty from './layoutComponents/empty';
import { src } from '@/utils/common';
import {
  HOME_PAGE_CONFIG,
  HEADER_CONFIG,
  FOOTER_CONFIG,
} from '@/utils/const/router';
import { useEffect, useRef } from 'react';
import { useUserAgentInfo } from '@/context/user-agent';
import { useRouter } from 'next/router';

const MOBILE_SIGN = 'mobile';
const WEB_SIGN = 'web';

function setRootFontSize(isMobile, ref) {
  const rootHtml = document.documentElement;
  const rootWidth = document.documentElement.clientWidth;
  if (isMobile) {
    const remUnit = rootWidth / 4.14;
    rootHtml.style.fontSize = `${remUnit}px`;
    ref.current.style.visibility = '';
  } else {
    rootHtml.style.fontSize = '';
  }
}

const FOOTER_THEME = {
  activity: {
    'background-color': '#010101',
  },
};

export default function Layout({ children }) {
  const { isMobile } = useUserAgentInfo();
  const router = useRouter();
  const key = router.route.split('/')[1];

  const ref = useRef();
  useEffect(() => {
    setRootFontSize(isMobile, ref);
  }, [isMobile]);

  const wait = false;
  if (wait) {
    return <Empty />;
  } else {
    return (
      <div
        className={`${styles.container}`}
        id={`${isMobile ? MOBILE_SIGN : WEB_SIGN}`}
        // NOTE：避免在移动端首屏还未计算出font-size导致的样式问题
        style={isMobile ? { visibility: 'hidden' } : {}}
        ref={ref}
      >
        {/* NOTE: seo用logo，在页面上不显示 */}
        <div className={styles['invisible-logo-for-seo']}>
          <img src={src('/dist/img/streamlake-logo1.png')} alt="logo" />
          <img src={src('/dist/img/streamlake-logo2.png')} alt="logo" />
        </div>
        <Header homePageConfig={HOME_PAGE_CONFIG} data={HEADER_CONFIG} />
        <div className={styles.content}>{children}</div>
        <Footer data={FOOTER_CONFIG} style={FOOTER_THEME[key]} />
      </div>
    );
  }
}
