export const isClient = typeof window === 'object';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { publicFolderPath } = publicRuntimeConfig;
/**@param {string} path */
export const src = function (path) {
  const isAbsolute =
    path?.indexOf('http://') === 0 || path?.indexOf('https://') === 0;
  if (isAbsolute || !publicFolderPath) {
    return path;
  }
  return `${publicFolderPath}${path}`;
};

import router from 'next/router';

export const handleRoute = (link) => {
  if (link && link.length) {
    router.push(link);
  }
};

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
}

export const phoneNumberValidator = (required = false) => {
  return (_, value) => {
    if (value) {
      const re = new RegExp(/^1[3-9]\d{9}$/);
      if (re.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error('请输入有效号码'));
      }
    } else {
      if (required) {
        return Promise.reject(new Error('请输入联系方式'));
      } else {
        return Promise.resolve();
      }
    }
  };
};

export function nanoId(n = 12): string {
  const ts = (Math.random() * 1e9) >>> 0;
  const randoms = [];
  const seed = '0123456789ABCDEF';
  for (let i = 0; i < n; i++) {
    randoms.push(seed.charAt(Math.random() * 16));
  }
  return ts + randoms.join('');
}

export function randomChoice<T>(choices: Array<T>): T {
  const index = Math.floor(Math.random() * choices.length);
  return choices[index];
}
