import { useEffect, useRef } from 'react';
import { formEvent as importedFormEvent } from '@/utils/const/weblogger/event';
import { IClickEvent } from '@/utils/weblogger';
import { localLog } from '@/utils/utils';
type TClickEvent = (clickEvent: IClickEvent) => void;

const useWebLogger = () => {
  let formEvent: typeof importedFormEvent = importedFormEvent;
  let clickEventRef = useRef<TClickEvent>(null);
  let showEventRef = useRef<typeof import('@/utils/weblogger').showEvent>(null);

  useEffect(() => {
    const loadFn = async () => {
      const weblogger = await import('@/utils/weblogger');
      clickEventRef.current = weblogger.clickEvent;
      showEventRef.current = weblogger.showEvent;
      localLog('weblogger.clickEvent is loaded');
    };
    loadFn();
  }, []);

  return {
    formEvent,
    clickEventRef,
    showEventRef,
  };
};
export default useWebLogger;
