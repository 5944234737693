import SvgIcon from '@/components/SvgIcon';

const style = { verticalAlign: 'sub' };

export default function Top(props) {
  const svg = () => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 28">
        <path
          id="Line 3"
          d="M3 4H19"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Line 4"
          d="M11 20L11 8"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector 7"
          d="M4 14L11 7L18 14"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
  return <SvgIcon component={svg} style={{ ...style }} {...props} />;
}
