import axios from 'axios';
import { clearUser } from '@/utils/utils';
import { captureException } from '@sentry/nextjs';

interface IErrorResponseData {
  message: string;
  response: {
    code: number;
    msg: string;
  };
  statusCode: number;
  statusText: string;
}

const isLoginError = (data: IErrorResponseData) =>
  data?.statusCode === 401 && data.response.code === 40001;

// front request for api server
const request = async ({ method, url, data, ...props }) => {
  const options: any = {
    url,
    method,
    ...props,
  };

  if (data) {
    if (method === 'get') {
      options.params = data;
    } else {
      options.data = data;
    }
  }
  try {
    const { data } = await axios(options);
    return data;
  } catch (err) {
    if (isLoginError(err?.response?.data)) {
      clearUser();
    } else {
      err.name = 'NextRequestError';
      captureException(err);
    }
  }
};

export default request;
