import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  headerRef: null,
};
const layoutContext = createContext({});

const { Provider } = layoutContext;

const LayoutStateProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'updateHeaderRef':
        return { ...state, headerRef: action.data };
      default:
        return state;
    }
  };
  const [layoutState, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ layoutState, dispatch }}>{children}</Provider>;
};

// function
function useLayout() {
  const context = useContext(layoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutStateProvider');
  }
  return context;
}

export { LayoutStateProvider, useLayout };
