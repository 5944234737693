import { Empty } from 'antd';

/**
 * 暂无文档的空状态，还没有设计稿，此为临时页面
 */
export default function Article() {
  return (
    <div
      style={{
        height: 'calc(100vh - 317px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Empty description="敬请期待" />
    </div>
  );
}
