import { useUserAgentInfo } from '@/context/user-agent';
import { Popover, Space } from 'antd';
import ScopedStyle from './index.module.scss';

import Consultation from './icons/Consultation';
import Top from './icons/Top';
import Phone from './icons/Phone';
import Card from './icons/Card';
import Arrow from './icons/Arrow';
import { useEffect, useState } from 'react';
import useWebLogger from '@/hooks/useWebLogger';
import { formEvent } from '@/utils/const/weblogger/event';
import { throttle } from 'lodash';
import getInnerUrl from '@/utils/get-inner-url';

//  const ['development', 'test'].includes(process.env.NEXT_PUBLIC_NODE_ENV);
const ConsultFormPage = getInnerUrl('/form/consult/experience');

function ConsultationInfo() {
  const { clickEventRef } = useWebLogger();

  return (
    <div className={ScopedStyle['consultation-info']}>
      <div className={ScopedStyle['header']}>
        <div className={ScopedStyle['label']}>售前咨询</div>
      </div>
      <div className={ScopedStyle['content']}>
        <div className={ScopedStyle['phone']}>
          <div className={ScopedStyle['icon']}>
            {/* <Image src={Phone} alt="phone" /> */}
            <Phone />
          </div>
          <div>
            <div>咨询电话</div>
            <div>400-104-6212</div>
          </div>
        </div>
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a
          href={ConsultFormPage}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            // 埋点上报
            clickEventRef.current({
              action: formEvent.CONSULTAION_CARD,
            });
          }}
        >
          <div className={ScopedStyle['qa']}>
            <div className={ScopedStyle['icon']}>
              {/* <Image src={Card} alt="issue" /> */}
              <Card />
            </div>
            <div>
              <div>业务咨询</div>
              <div className={ScopedStyle['description']}>
                我们会第一时间处理您的问题
              </div>
            </div>
            <Arrow />
          </div>
        </a>
      </div>
    </div>
  );
}

export default function GlobalPortal() {
  const { isMobile } = useUserAgentInfo();
  const { clickEventRef, showEventRef } = useWebLogger();
  const jumpToTop = () => {
    // scroll with smooth
    // 埋点上报
    clickEventRef.current({
      action: formEvent.ScrollToTop,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [isPopoverShow, setIsPopoverShow] = useState(false);

  const [isTopButtonShow, setIsTopButtonShow] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      //  垂直滚动 400px 后显示
      if (document.documentElement.scrollTop > 400) {
        setIsTopButtonShow(true);
      } else {
        setIsTopButtonShow(false);
      }
    }, 100);
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isPopoverShow) {
      showEventRef.current({
        action: formEvent.CONSULTAION_CARD,
      });
    }
  }, [isPopoverShow]);

  if (isMobile) {
    return null;
  }
  return (
    <div
      style={{
        position: 'fixed',
        right: 8,
        bottom: 90,
        zIndex: 9999,
      }}
    >
      <Space size={12} direction="vertical">
        <Popover
          placement="left"
          content={<ConsultationInfo />}
          trigger="hover"
          overlayClassName={ScopedStyle['popover']}
          showArrow={true}
          arrowPointAtCenter={true}
          onOpenChange={(visible) => {
            setIsPopoverShow(visible);
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          mouseEnterDelay={0.01}
        >
          <div
            className={
              ScopedStyle['consultation'] +
              ' ' +
              (isPopoverShow ? ScopedStyle['active'] : '')
            }
          >
            {/* <Image src={Consultation} alt="contact" /> */}
            <Consultation />
            {/* NOTE: 用 css 实现一个竖排文字 */}
            <div className={ScopedStyle['text']}>售前咨询</div>
          </div>
        </Popover>
        <div
          className={
            ScopedStyle['top-button'] +
            ' ' +
            (isTopButtonShow ? ScopedStyle['visible'] : '')
          }
          onClick={jumpToTop}
        >
          <Top />
        </div>
      </Space>
    </div>
  );
}
