import SvgIcon from '@/components/SvgIcon';

const style = { verticalAlign: 'sub' };

export default function Phone(props) {
  const svg = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="phone-fill 1">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.05262 6.48011C1.05262 12.6832 6.09949 17.7301 12.3026 17.7301C13.3704 17.732 14.4023 17.3452 15.2057 16.6419C16.009 15.9386 16.5289 14.9669 16.6682 13.9082C16.7008 13.6403 16.6456 13.369 16.5112 13.1349C16.3767 12.9009 16.17 12.7167 15.9221 12.6098L12.2417 10.9606L12.2315 10.9559C12.0405 10.8742 11.832 10.8414 11.6251 10.8605C11.4182 10.8796 11.2193 10.95 11.0464 11.0653C11.026 11.0787 11.0064 11.0933 10.9878 11.109L9.07893 11.8091C8.55261 11.8091 6.97366 10.2301 6.97366 9.70379L7.67918 7.79027C7.69481 7.77074 7.70965 7.75121 7.72371 7.73011C7.83632 7.55708 7.90431 7.35886 7.92162 7.15314C7.93894 6.94742 7.90503 6.74062 7.82293 6.55121V6.54183L6.17293 2.85824C6.0657 2.61079 5.88131 2.40466 5.6473 2.27061C5.41328 2.13657 5.14219 2.0818 4.87449 2.11449C3.81588 2.25379 2.84417 2.77368 2.14085 3.57706C1.43753 4.38044 1.05071 5.41237 1.05262 6.48011ZM4.83076 3.72432C4.25029 3.85467 3.7236 4.16621 3.32886 4.61711C2.8782 5.13189 2.63034 5.79311 2.63156 6.47728L2.63157 6.48011C2.63157 11.8112 6.97152 16.1512 12.3026 16.1512H12.3054C12.9896 16.1524 13.6508 15.9045 14.1656 15.4539C14.6163 15.0593 14.9277 14.533 15.0582 13.9529L11.7711 12.4799C11.6946 12.5237 11.6145 12.561 11.5314 12.5914L9.62257 13.2915C9.44844 13.3553 9.26441 13.388 9.07893 13.388C8.66697 13.388 8.33334 13.2484 8.16278 13.1678C7.96083 13.0723 7.77551 12.9553 7.61927 12.8454C7.30457 12.6239 6.98856 12.3464 6.71244 12.0703C6.43633 11.7942 6.15878 11.4782 5.93733 11.1635C5.82738 11.0072 5.71037 10.8219 5.61495 10.6199C5.53435 10.4494 5.39472 10.1158 5.39472 9.70379C5.39472 9.5174 5.42772 9.33247 5.4922 9.15758L6.19772 7.24405C6.22706 7.16448 6.26271 7.08753 6.30428 7.01392L4.83076 3.72432ZM4.72417 3.48607L4.7258 3.48975C4.72525 3.48853 4.72471 3.4873 4.72417 3.48607Z"
          fill="#0D0D0D"
        />
        <path
          id="Vector 5 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7568 5.08689C9.85414 4.66189 10.2776 4.39626 10.7026 4.49361C11.7253 4.72784 13.9264 5.79134 14.3542 8.28575C14.4279 8.71549 14.1392 9.12361 13.7095 9.1973C13.2798 9.271 12.8716 8.98237 12.7979 8.55263C12.5238 6.95426 11.0681 6.19716 10.3501 6.0327C9.92507 5.93535 9.65945 5.5119 9.7568 5.08689Z"
          fill="#0D0D0D"
        />
        <path
          id="Vector 6 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6151 2.02809C11.7026 1.60095 12.1198 1.32562 12.5469 1.41312C14.0346 1.71788 16.993 3.31185 17.7855 7.23787C17.8718 7.66526 17.5953 8.08167 17.1679 8.16795C16.7405 8.25423 16.3241 7.97769 16.2378 7.5503C15.5949 4.36549 13.2264 3.16404 12.2301 2.95994C11.8029 2.87244 11.5276 2.45524 11.6151 2.02809Z"
          fill="#0D0D0D"
        />
      </g>
    </svg>
  );
  return <SvgIcon component={svg} style={{ ...style }} {...props} />;
}
