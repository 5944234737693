import styles from './style.module.scss';
import { useState, useEffect, useRef } from 'react';
import { Button, Popover, Menu, Avatar, Dropdown, message, Badge } from 'antd';
import { WebPNextImage as Image } from '../../WebPImage';
import groupBy from 'lodash/groupBy';
import { useRouter } from 'next/router';
import { useLayout } from '/context/layout';
import SubMenu from 'antd/lib/menu/SubMenu';
import request from '../../../utils/request/next-request';
import { useUserAgentInfo } from '@/context/user-agent';
import { clearUser } from '@/utils/utils';
import classnames from 'classnames';
import Head from 'next/head';
import { DEBUG_PANEL } from '@/utils/constants.js';
import { getQueryVariable } from '@/utils/common';
import Cookies from 'js-cookie';
import WechatShare from '@/utils/wechat-share';
import DropdownMenus from './DropdownMenus';
import { EXPENSE_CENTER, ACCOUNT_INFO, logout } from './utils';
import { src } from '@/utils/common';
import getInnerUrl from '@/utils/get-inner-url';
import Link from 'next/link';

const arrowRight = '/img/doc-center/arrowRight.svg';

export const returnToWithControl = (
  link,
  accessControl = false,
  isMobile = false
) => {
  if (accessControl) {
    message.info('暂未开放！');
    return;
  }
  if (isMobile) {
    return '/empty/transfer';
  } else {
    return link;
  }
};

const HeaderItem = (props) => {
  const { item, chosen, onClick = () => undefined } = props;
  if (item?.link?.length) {
    return (
      <Link
        href={item.link}
        legacyBehavior={false}
        onClick={(e) => e?.preventDefault()}
        passHref
      >
        <div
          className={`${
            chosen
              ? `${styles['popover-item']} ${styles['chosen']}`
              : styles['popover-item']
          } ${styles.active}`}
          onClick={onClick}
        >
          {item.title}
          {chosen && (
            <Image
              src="/img/check.webp"
              alt="check"
              width={14}
              height={14}
            ></Image>
          )}
        </div>
      </Link>
    );
  }
  return (
    <div
      className={`${styles.popoverItem}`}
      style={{ opacity: '1', color: '#b1b1b1', cursor: 'default' }}
    >
      {item.title}
      <Image
        src="/img/todo.webp"
        alt="建设中"
        width={36}
        height={14}
        style={{ verticalAlign: 'middle' }}
      ></Image>
    </div>
  );
};

function Header({ homePageConfig, data }) {
  const router = useRouter();
  const [active, setActive] = useState(-1);
  const [secondActive, setSecondActive] = useState(-1);
  const [thirdActive, setThirdActive] = useState(-1);
  const [userName, setUserName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [validateStatus, setValidateStatus] = useState('');
  // const [config, setConfig] = useState({});
  const headerRef = useRef(null);
  const { dispatch } = useLayout();
  const [clickFlag, setClickFlag] = useState(false);
  const [popoverType, setPopoverType] = useState('');

  const { isMobile } = useUserAgentInfo();

  const wechatShare = useRef();

  const navMobileData = [homePageConfig, ...data];

  /**
   * @description: 通过URL判断是否处于debug模式
   */
  useEffect(() => {
    const isDebug = getQueryVariable('isDebug');
    wechatShare.current = new WechatShare({ debug: isDebug });
    if (isDebug) {
      Cookies.set(DEBUG_PANEL, isDebug);
    }
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  /**
   * @description: 在初始化时将HeaderRef传给context
   */
  useEffect(() => {
    if (headerRef.current) {
      dispatch({
        type: 'updateHeaderRef',
        data: headerRef.current,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    // NOTE: 只在移动端要响应这个overflow hidden 的行为
    if (clickFlag && isMobile) {
      const documentOverflowBefore = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = documentOverflowBefore;
      };
    }
  }, [clickFlag]);

  useEffect(() => {
    const path = router.pathname;
    let config = homePageConfig;
    if (path === '/') {
      init();
    } else {
      for (let i = 0; i < data.length; ++i) {
        if (data[i].children) {
          const c = data[i].children;
          for (let j = 0; j < c.length; ++j) {
            if (c[j].items) {
              for (let k = 0; k < c[j].items.length; ++k) {
                if (c[j].items[k].link === path) {
                  setThirdActive(k);
                  setSecondActive(j);
                  setActive(i);
                  config = c[j].items[k];
                  break;
                }
              }
            } else {
              if (c[j].link === path) {
                setSecondActive(j);
                setActive(i);
                config = c[j];
                break;
              }
            }
          }
        } else {
          if (data[i].link === path) {
            setActive(i);
            // setConfig(data[i]);
            config = data[i];
            break;
          }
        }
      }
    }
    // setConfig(config);
    wechatShare.current.updateConfig({
      title: config.customTitle || config.title,
      desc: config.description,
    });
  }, [router.pathname]);

  // init header
  const getUserInfo = async () => {
    try {
      const res = await request({
        url: `${process.env.NEXT_PUBLIC_CONSOLE_AUTH_URL}/console/info`,
        method: 'get',
        withCredentials: true,
        credentials: 'include',
      });
      if (res) {
        const { data } = res;
        setUserName(
          data.data?.accountInfo?.accountName ||
            data.data?.userInfo?.userName ||
            'unknown'
        );
        setCustomerId(data.data?.customerInfo?.customerId);
        setValidateStatus(
          data.data?.customerInfo?.validateInfo?.validateStatus
        );
      }
    } catch (e) {
      clearUser();
    }
  };

  const init = () => {
    setThirdActive(-1);
    setSecondActive(-1);
    setActive(-1);
    // setConfig(homePageConfig);
  };

  const handleClick = (link) => {
    if (link) {
      router.push(link, undefined);
      setClickFlag(false);
    }
  };

  const bodyScrollForbidden = () => {
    return {
      onMouseEnter: () => {
        // 右边留出滚动条占位空间，防止抖动
        document.body.style.paddingRight = `${
          window.innerWidth - document.body.clientWidth
        }px`;
        document.body.style.overflowY = 'hidden';
      },
      onMouseLeave: () => {
        document.body.style.overflowY = '';
        document.body.style.paddingRight = '';
      },
    };
  };

  const getContent = (children, index) => {
    if (children[0].items) {
      // 产品模块，横版二级菜单
      return (
        <div className={styles['popover-wrapper']} {...bodyScrollForbidden()}>
          {children.map((b, bi) => (
            <div className={styles['popover-block']} key={bi}>
              <div className={styles['block-title']}>{b.title}</div>
              <div className={styles['block-content']}>
                {b.subTitle
                  ? b.subTitle.map((t, ti) => {
                      b.items.map((el, index) => (el.index = index));
                      const items = groupBy(b.items, 'type');
                      return (
                        <div
                          className={classnames({
                            [styles['type-block']]: true,
                            [styles['type-block-col']]:
                              b.blockLayout[ti] === 'col',
                          })}
                          key={ti}
                        >
                          <div className={styles['title']}>{t}</div>
                          <div className={styles['content']}>
                            {items[ti]?.map((item, key) => {
                              const chosen =
                                active === index &&
                                secondActive === bi &&
                                thirdActive === item.index;
                              return (
                                <HeaderItem
                                  item={item}
                                  chosen={chosen}
                                  key={key}
                                  onClick={() => handleClick(item.link)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  : b.items.map((item, i) => {
                      const chosen =
                        active === index &&
                        secondActive === bi &&
                        thirdActive === i;
                      return (
                        <HeaderItem
                          item={item}
                          chosen={chosen}
                          key={i}
                          onClick={() => handleClick(item.link)}
                        />
                      );
                    })}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      // 其他模块，竖版二级菜单
      return (
        <div className={styles['popover-wrapper']} {...bodyScrollForbidden()}>
          {children.map((item, i) => {
            const chosen = active === index && secondActive === i;
            return (
              <HeaderItem
                item={item}
                chosen={chosen}
                key={i}
                onClick={() => handleClick(item.link)}
              />
            );
          })}
        </div>
      );
    }
  };

  const getMenu = (menu, index) => {
    if (menu.link) {
      return (
        <Link
          key={index}
          href={menu.link}
          legacyBehavior={false}
          passHref
          onClick={(e) => e?.preventDefault()}
        >
          <div
            className={styles.menu}
            key={index}
            onClick={() => handleClick(menu.link)}
          >
            {menu.title}
            {/* {menu.icon && (
        <Image src={menu.icon} alt={menu.title} width={36} height={14} />
      )} */}
            {menu.icon && (
              <div className={styles['icon']}>
                <div className={styles['text']}>{menu.icon}</div>
              </div>
            )}
          </div>
        </Link>
      );
    }

    return (
      <div className={styles.menu} key={index}>
        {menu.title}
        {/* {menu.icon && (
        <Image src={menu.icon} alt={menu.title} width={36} height={14} />
      )} */}
        {menu.icon && (
          <div className={styles['icon']}>
            <div className={styles['text']}>{menu.icon}</div>
          </div>
        )}
      </div>
    );
  };
  // 解决方案
  const SoluCard = ({ menuChild, index }) => {
    return (
      <>
        {menuChild.map((childItem, index3) => {
          const { title, link } = childItem;
          return (
            link && (
              <Menu.Item
                className={styles['solu-wrapper']}
                key={`${index}-${index3}`}
                onClick={() => handleClick(link)}
              >
                <div className={styles['solu-title']}>{title}</div>
              </Menu.Item>
            )
          );
        })}
      </>
    );
  };

  // 移动端产品组件
  const ProdCardMobile = ({ items, title, type }) => {
    const filterItems = items.filter((item) => item.type === type);

    return (
      <div className={styles['prod-wrapper']}>
        <div className={styles['prod-title']}>{title}</div>
        <div className={styles['divider']} />
        <SoluCard menuChild={filterItems} index={type} />
      </div>
    );
  };

  // 控制台
  const gotoConsole = () => {
    access_control &&
      window.open(
        `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/home/index`,
        '_blank'
      );
  };

  const LoginMenuCard = (props) => {
    return (
      <>
        <div className={styles['account-link']} onClick={props.handleMenuClick}>
          <div className={styles.titleInfo}>
            <div className={styles.title}>
              <div className={styles.name}>{props.name}</div>
            </div>
          </div>
          {props.showRightIcon && (
            <div className={styles.rightArrow}>
              <Image src={arrowRight} alt="arrowRight.svg" layout="fill" />
            </div>
          )}
          <div className={styles.oneLine}></div>
        </div>
      </>
    );
  };

  const LoginAccountCard = (props) => {
    const isCertified = props.validateStatus === 'certified';
    return (
      <div className={styles['account-info']}>
        <Badge
          count={isCertified ? '个人' : '未实名'}
          // style={{ backgroundColor: isCertified ? '#326BFB' : '#898A8C' }}
          className={classnames({
            [styles['status-is-certified']]: isCertified,
            [styles['status-is--not-certified']]: !isCertified,
          })}
          offset={[-26, 45]}
        >
          <Avatar
            style={{
              color: '#fdfdfd',
              backgroundColor: '#c5d1f3',
              verticalAlign: 'middle',
            }}
            size={52}
          >
            {userName.slice(0, 1).toUpperCase() || ''}
          </Avatar>
        </Badge>
        <div className={styles['account-name']}>
          <div>{userName}</div>
          <div className={styles['account-id']}>用户ID： {customerId}</div>
        </div>
      </div>
    );
  };

  const access_control = true;

  return (
    <>
      {!(router.route.match(/^\/activity\/.*$/) && !isMobile) && (
        <div className={styles.header} ref={headerRef} key="header">
          <div className={styles.left}>
            <a className={styles.logo} href={getInnerUrl('/')}>
              <Image src="/img/logo.webp" alt="logo" width={142} height={25} />
            </a>
            {data.map((menu, index) => {
              if (menu.children) {
                return (
                  <Popover
                    content={getContent(menu.children, index)}
                    key={index}
                    placement="bottomLeft"
                  >
                    {getMenu(menu, index)}
                  </Popover>
                );
              } else return getMenu(menu, index);
            })}
          </div>
          <div className={styles.right}>
            {clickFlag ? (
              <div
                className={`${styles.hidePopOver} ${styles.rightIcon}`}
                onClick={() => setClickFlag(false)}
              ></div>
            ) : (
              <div className={styles.rightWrapper}>
                {userName ? (
                  <div
                    className={`${styles.accountLogin} ${styles.rightIcon}`}
                    onClick={() => {
                      setClickFlag(true);
                      setPopoverType('account');
                    }}
                  ></div>
                ) : (
                  <div
                    className={`${styles.accountNotLogin} ${styles.rightIcon}`}
                    onClick={() => {
                      setClickFlag(true);
                      setPopoverType('login');
                    }}
                  ></div>
                )}
                <div
                  className={`${styles.menuShow} ${styles.rightIcon}`}
                  onClick={() => {
                    setClickFlag(true);
                    setPopoverType('menu');
                  }}
                ></div>
              </div>
            )}
            {/* <div className={styles.menu}>体验馆</div> */}
            <a
              href={`${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/home/index`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.preventDefault()}
            >
              <div className={styles.menu} onClick={gotoConsole}>
                控制台
              </div>
            </a>
            {userName ? (
              <div className={styles.menu}>
                <Avatar
                  style={{
                    width: '32px',
                    color: '#fdfdfd',
                    backgroundColor: '#c5d1f3',
                  }}
                >
                  {userName.slice(0, 1).toUpperCase() || ''}
                </Avatar>
                <Dropdown overlay={<DropdownMenus userName={userName} />}>
                  <span style={{ paddingLeft: 12, cursor: 'pointer' }}>
                    <Image
                      src={'/img/doc-center/arrowDown.svg'}
                      width={8}
                      height={8}
                      alt="down"
                    ></Image>
                  </span>
                </Dropdown>
              </div>
            ) : (
              <>
                <a
                  href={`${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/tenant-login`}
                  target="_self"
                  onClick={(e) => e.preventDefault()}
                >
                  <div
                    className={styles.menu}
                    onClick={() => {
                      access_control &&
                        window.location.assign(
                          `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/tenant-login?returnTo=${window.location.href}`
                        );
                    }}
                  >
                    登录
                  </div>
                </a>
                <a
                  href={`${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/sign-up/tenant`}
                  target="_self"
                  onClick={(e) => e.preventDefault()}
                >
                  <Button
                    type="primary"
                    className={styles.button}
                    onClick={() => {
                      access_control &&
                        window.location.assign(
                          `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/sign-up/tenant?returnTo=${window.location.href}`
                        );
                    }}
                  >
                    立即注册
                  </Button>
                </a>
              </>
            )}
          </div>
        </div>
      )}
      {clickFlag ? (
        //NOTE: 在 web(桌面端),  pop-menu 会被设置成 display:none
        <>
          {popoverType === 'menu' && (
            <div className={styles['pop-menu']}>
              <div className={styles['nav-menu']}>
                <Menu
                  defaultSelectedKeys={['-1']}
                  defaultOpenKeys={['1-1']}
                  mode="inline"
                  inlineCollapsed={false}
                >
                  {navMobileData.map((menu, index) => {
                    if (menu.children && menu.key === 'products') {
                      return (
                        <>
                          {menu.children.map((product, productIndex) => {
                            if (product.title === 'StreamLake-Video') {
                              return (
                                <SubMenu
                                  key={productIndex}
                                  title={menu.children[0].title}
                                  popupClassName={styles['solu']}
                                >
                                  <SoluCard
                                    menuChild={menu.children[0].items}
                                    index={index}
                                  />
                                </SubMenu>
                              );
                            } else {
                              return (
                                <SubMenu
                                  key={index}
                                  title={menu.children[1].title}
                                  popupClassName={styles['prod']}
                                >
                                  {menu.children[1].subTitle.map(
                                    (title, titleIndex) => {
                                      return (
                                        <ProdCardMobile
                                          key={titleIndex}
                                          items={menu.children[1].items}
                                          type={titleIndex}
                                          title={title}
                                        />
                                      );
                                    }
                                  )}
                                </SubMenu>
                              );
                            }
                          })}
                        </>
                      );
                    } else if (menu.children && menu.key === 'solutions') {
                      return (
                        <SubMenu
                          key={index}
                          title={menu.title}
                          popupClassName={styles['solu']}
                        >
                          <SoluCard menuChild={menu.children} index={index} />
                        </SubMenu>
                      );
                    } else {
                      return (
                        <Menu.Item
                          key={index}
                          onClick={() => handleClick(menu.link)}
                        >
                          {menu.title}
                        </Menu.Item>
                      );
                    }
                  })}
                </Menu>
              </div>
            </div>
          )}
          {popoverType === 'login' && (
            <>
              <div className={styles['pop-wrapper']}></div>
              <div className={styles['pop-login']}>
                <div className={styles['pop-login-button']}>
                  <Button
                    type="primary"
                    className={styles.button}
                    onClick={() => {
                      access_control &&
                        window.location.assign(
                          `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/sign-up/tenant?returnTo=${window.location.href}`
                        );
                    }}
                  >
                    立即注册
                  </Button>
                  <Button
                    className={styles.button}
                    onClick={() => {
                      access_control &&
                        window.location.assign(
                          `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/user/tenant-login?returnTo=${window.location.href}`
                        );
                    }}
                  >
                    登录
                  </Button>
                </div>
              </div>
            </>
          )}
          {popoverType === 'account' && (
            // NOTE: 移动端的个人中心
            <>
              <div className={styles['pop-wrapper']}></div>
              <div className={styles['pop-account']}>
                <LoginAccountCard validateStatus={validateStatus} />
                <LoginMenuCard
                  name={'控制台'}
                  handleMenuClick={gotoConsole}
                  showRightIcon
                />
                <LoginMenuCard
                  showRightIcon
                  name={'费用中心'}
                  handleMenuClick={() => {
                    window.open(EXPENSE_CENTER, '_blank');
                  }}
                />
                <LoginMenuCard
                  showRightIcon
                  name={'账号中心'}
                  handleMenuClick={() => {
                    window.open(ACCOUNT_INFO, '_blank');
                  }}
                />
                <LoginMenuCard
                  name={'退出登录'}
                  handleMenuClick={() => {
                    logout();
                  }}
                />
                <div className={styles['service-hotline']}>
                  24小时服务热线：400-104-6212
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

export default Header;
