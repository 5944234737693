import SvgIcon from '@/components/SvgIcon';

const style = { verticalAlign: 'sub' };

export default function Card(props) {
  const svg = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="clipboard-text 1">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 3.5H15C15.2761 3.5 15.5 3.72386 15.5 4V16C15.5 16.2761 15.2761 16.5 15 16.5H5C4.72386 16.5 4.5 16.2761 4.5 16V4C4.5 3.72386 4.72386 3.5 5 3.5H7.5L8.25 2H5C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V4C17 2.89543 16.1046 2 15 2H12.1875L12.75 3.5Z"
          fill="#0D0D0D"
        />
        <path
          id="Rectangle 15 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 4H12.5C12.5 2.61929 11.3807 1.5 10 1.5C8.61929 1.5 7.5 2.61929 7.5 4ZM10 0C7.79086 0 6 1.79086 6 4V4.5C6 5.05228 6.44772 5.5 7 5.5H13C13.5523 5.5 14 5.05228 14 4.5V4C14 1.79086 12.2091 0 10 0Z"
          fill="#0D0D0D"
        />
        <path
          id="Line 1 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H12C12.4142 9.25 12.75 9.58579 12.75 10C12.75 10.4142 12.4142 10.75 12 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10Z"
          fill="#0D0D0D"
        />
        <path
          id="Line 2 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H12C12.4142 12.25 12.75 12.5858 12.75 13C12.75 13.4142 12.4142 13.75 12 13.75H8C7.58579 13.75 7.25 13.4142 7.25 13Z"
          fill="#0D0D0D"
        />
      </g>
    </svg>
  );
  return <SvgIcon component={svg} style={{ ...style }} {...props} />;
}
