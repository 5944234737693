import { useCallback } from 'react';
import { useWebPSupport } from '../context/webp-support';

const isWebPResource = (url: string = '') => {
  return !!url
    .split('/')
    ?.pop()
    ?.split('?')?.[0]
    ?.toLowerCase()
    ?.endsWith('.webp');
};

// NOTE: 这一步依赖 {PROJECT_PATH}/webp2png.js 这个脚本,
// 把public/img 目录下的 *.webp 文件编码成了 *.png 文件, 放到了 public/dist/img 这个目录下

const replaceWebPWithPNG = (webPUrl: string) => {
  // FIXME: 这里的替换是很不安全的 对未来不友好的 之后建议进一步调整
  // 比如脚本和这个 hook 共用一个配置文件
  // 另外这里的 replace 会替换从做到右第一个匹配的子串, 所以目前来看是安全的
  // 但是未来可能会发生变化
  return webPUrl.replace('img/', 'dist/img/').replace('.webp', '.png');
};

/**
 * 如果没有提供 fallback, 就会按照默认规则转制成 png
 *  '/img/exception/404.webp' => '/dist/img/exception/404.png'
 * @param resourceUrl
 * @returns
 */
const useWebPResource = (resourceUrl: string, fallbackUrl?: string) => {
  const { support: isSupportWebP } = useWebPSupport();
  // const isSupportWebP = false;
  if (!isWebPResource(resourceUrl)) {
    return resourceUrl;
  }
  if (isSupportWebP) {
    return resourceUrl;
  }

  if (fallbackUrl) {
    return fallbackUrl;
  }
  // isWebP && webp not Supported
  const res = replaceWebPWithPNG(resourceUrl);
  return res;
};

export const useWebPResourceCompatibleFn = () => {
  // const isSupportWebP = false;
  const { support: isSupportWebP } = useWebPSupport();
  const webPResourceCompatibleFn = useCallback(
    (url: string, fallbackUrl?: string) => {
      if (!isWebPResource(url)) {
        return url;
      }
      if (isSupportWebP) {
        return url;
      }
      if (fallbackUrl) {
        return fallbackUrl;
      }
      // isWebP && webp not Supported
      const replacedUrl = replaceWebPWithPNG(url);
      return replacedUrl;
    },
    [isSupportWebP]
  );
  return webPResourceCompatibleFn;
};

export default useWebPResource;
