import SvgIcon from '@/components/SvgIcon';

const style = { verticalAlign: 'sub' };

export default function Arrow(props) {
  const svg = () => (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="&#232;&#183;&#175;&#229;&#190;&#132; 2"
        d="M0.717445 0.204289C0.992113 -0.0537603 1.42846 -0.0673419 1.72014 0.163545L1.76736 0.204289L5.28256 3.5068C5.55722 3.76485 5.57168 4.17479 5.32592 4.44883L5.28256 4.4932L1.76736 7.79571C1.47744 8.0681 1.00737 8.0681 0.717445 7.79571C0.442778 7.53766 0.428321 7.12772 0.674077 6.85368L0.717445 6.80931L3.70768 4L0.717445 1.19069C0.442778 0.932636 0.428321 0.522693 0.674077 0.248655L0.717445 0.204289Z"
        fill="#0D0D0D"
      />
    </svg>
  );
  return <SvgIcon component={svg} style={{ ...style }} {...props} />;
}
