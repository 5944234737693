import { Menu } from 'antd';
import styles from './style.module.scss';
import Link from 'next/link';
import { returnToWithControl } from '.';
import { logout, EXPENSE_CENTER, ACCOUNT_INFO } from './utils';

/**
 * PC Web端 个人中心
 */
const DropdownMenus = ({ userName }: { userName: string }) => {
  return (
    <Menu>
      <div className={styles['profile-container']}>
        <div className={styles['user-name']}>用户 {userName}</div>
        <div style={{ color: '#898A8C' }}>
          {/* <div className={styles['profile-menu']}>
            <a
              href={`${process.env.NEXT_PUBLIC_VOD_BASE_URL}/user-info/info`}
              target="_blank"
              rel="noreferrer"
            >
              账号中心(旧)
            </a>
          </div> */}
          <div className={styles['profile-menu']}>
            <Link
              href={returnToWithControl(ACCOUNT_INFO)}
              target="_blank"
              rel="noreferrer"
            >
              账号中心
            </Link>
          </div>
          <div className={styles['profile-menu']}>
            <a href={EXPENSE_CENTER} target="_blank" rel="noreferrer">
              费用中心
            </a>
          </div>
          {/* <div
            className={styles['profile-menu']}
            style={{ color: 'rgba(0,0,0,.25)', cursor: 'no-drop' }}
          >
            工单系统中心
          </div> */}
          <div className={styles['profile-menu']} onClick={logout}>
            退出登录
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default DropdownMenus;
