import React, { createContext, useContext, useEffect, useState } from 'react';

const detectIfSupportWebP = () =>
  new Promise((resolve: (flag: boolean) => void) => {
    const image = new Image();
    image.onerror = () => resolve(false);
    image.onload = () => resolve(image.width === 1);
    image.src =
      'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
  }).catch(() => false);

const initialState = {
  // 默认支持 webp
  support: true,
} as const;

const WebPContext = createContext<{ support: boolean }>(initialState);

const { Provider } = WebPContext;

const WebPSupportsProvider = ({ children }) => {
  const [isSupportWebP, setSupportWebP] = useState(
    initialState.support as boolean
  );
  useEffect(() => {
    detectIfSupportWebP().then((isSupport) => setSupportWebP(isSupport));
  }, []);

  return (
    <Provider
      value={{
        support: isSupportWebP,
        // support: isSupportWebP,
      }}
    >
      {children}
    </Provider>
  );
};

// function
function useWebPSupport() {
  const context = useContext(WebPContext);
  if (context === undefined) {
    // NOTE: 默认支持
    return { ...initialState };
  }
  return context;
}

export { WebPSupportsProvider, useWebPSupport };
