import { forwardRef, ForwardedRef } from 'react';
import useWebPResource from '../hooks/useWebPResource';
interface WithImageSrcProps {
  src: string;
}
function withWebPSource<T>(
  WrappedComponent: React.ComponentType<T & WithImageSrcProps>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<T & WithImageSrcProps> & React.RefAttributes<any>
> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithWebP = (
    props: T &
      WithImageSrcProps & {
        forwardref: ForwardedRef<any>;
      }
  ) => {
    const { src, forwardref } = props;
    // Fetch the props you want to inject. This could be done with context instead.
    const safeWebPUrl = useWebPResource(src);
    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...props} ref={forwardref} src={safeWebPUrl} />;
  };

  ComponentWithWebP.displayName = `withWebPSource(${displayName})`;

  const ComponentWithWebPWithRef = forwardRef(
    (props: T & WithImageSrcProps, ref: ForwardedRef<any>) => (
      <ComponentWithWebP {...props} forwardref={ref} />
    )
  );
  ComponentWithWebPWithRef.displayName = 'ComponentWithWebPWithRef';

  return ComponentWithWebPWithRef;
}

export default withWebPSource;
