// 在此维护埋点事件相关内容
// 表单相关埋点
export const formEvent = {
  BookSubmit: 'SUBMIT', // 预约直播提交事件 https://data-track.corp.kuaishou.com/#/entity/detail?entityCombineId=1829257&appName=streamlake_website&submitDevPageType=ENTITY_NORMAL_EVENT&fromPage=entityList
  BookValidate: 'IDENTIFY', // 发布会预约验证码
  ArticleUsefulFeedback: 'CMS_ARTICLE_DETAIL_IS_USEFUL', // 文档是否有帮助
  CMSSearchTool: 'CMS_DOC_SEARCH_TOOL', // 文档搜索功能使用统计
  ScrollToTop: 'SCROLL_TOP', //滚动到顶部
  CONSULTAION_CARD: 'CONSULTAION_CARD', // 咨询卡片点击
};
