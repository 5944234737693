import SvgIcon from '@/components/SvgIcon';

const style = { verticalAlign: 'sub' };

export default function Consultation(props) {
  const svg = () => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 4">
        <path
          id="Vector 3"
          d="M19 10H16C15.4477 10 15 10.4477 15 11V15C15 15.5523 15.4477 16 16 16H19"
          stroke="#0D0D0D"
          strokeWidth="1.5"
        />
        <path
          id="Vector 2"
          d="M3 10.1818H6.26667C6.81895 10.1818 7.26667 10.6295 7.26667 11.1818V15.1818C7.26667 15.7341 6.81895 16.1818 6.26667 16.1818H3.88636C3.39684 16.1818 3 15.785 3 15.2955V10C3 5.58172 6.58172 2 11 2V2C15.4183 2 19 5.58172 19 10V17C19 18.6569 17.6569 20 16 20H11"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
  return <SvgIcon component={svg} style={{ ...style }} {...props} />;
}
