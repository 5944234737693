import { createContext, useContext, useEffect, useState } from 'react';
type Default = 'default';
type BrowserName = 'WeChat' | 'Mobile Safari' | 'UCBrowser' | 'QQBrowser';
type OSName = 'Android' | 'iOS';
type MobileVendor = 'Apple' | 'Huawei';

type UAInfo = {
  isMobile: boolean; // 默认为pc view
  isIOS: boolean;
  isAndroid: boolean;
  osVersion: string; // '10', '15.5'
  browserVersion: string; //'8'
  osName: Default | OSName;
  browserName: Default | BrowserName;
  mobileVendor: Default | MobileVendor;
};

const initialState: UAInfo = {
  isMobile: true, // 默认为pc view
  isIOS: false,
  isAndroid: false,
  osVersion: '',
  browserVersion: '',
  osName: 'default',
  browserName: 'default',
  mobileVendor: 'default',
};

const userAgentContext = createContext<UAInfo>(initialState);
const { Provider } = userAgentContext;

const UserAgentProvider = ({ value, children }) => {
  return <Provider value={value}>{children}</Provider>;
};

function useUserAgentInfo() {
  const context = useContext(userAgentContext);
  return context;
}

export { UserAgentProvider, useUserAgentInfo };
