import Image, { ImageProps } from 'next/image';
import React from 'react';

import withWebPSource from '../hoc/withWebP';

export const WebPNextImage = withWebPSource<any>(Image);

const NativeImage = React.forwardRef(
  (
    props: React.ImgHTMLAttributes<HTMLImageElement>,
    ref: React.MutableRefObject<HTMLImageElement>
  ) => <img {...props} ref={ref}></img>
);
NativeImage.displayName = 'NativeImage';
export const WebPNativeImage = withWebPSource(NativeImage);
