import styles from './style.module.scss';
import Link from 'next/link';

function Footer({ data, style }) {
  return (
    <div className={styles.footer} style={style}>
      <div className={styles.links}>
        {data.map((group, index) => (
          <div key={index}>
            <div>{group.title}</div>
            <div className={styles.linkGroup}>
              {group.items.map((item, i) => (
                <Link
                  key={i}
                  href={item.link}
                  className={styles.link}
                  target="_blank"
                >
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.info}>
        <div style={{ marginTop: '16px' }}>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            京ICP备19034532号-192
          </a>
          通信电信许可证 京B2-20202468. B1-20202319 版权所有
          @北京溪流湖科技有限公司 2022 版权所有
        </div>
      </div>
      <div className={styles['mobile-info']}>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          京ICP备19034532号-192
        </a>
        <span>通信电信许可证 京B2-20202468. B1-20202319</span>
        <span>版权所有 @北京溪流湖科技有限公司 2022 版权所有</span>
      </div>
    </div>
  );
}

export default Footer;
