import Cookies from 'js-cookie';
export const clearUser = () => {
  Cookies.remove('username'); // 清理cookie
};
export const localLog = (...params) => {
  if (localStorage && localStorage.isEnableLog === '1') {
    // 在localStorage里面控制日志
    console.log(params);
  }
};
