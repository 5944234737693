var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hKcn1V-w3ReXoGPqYSS4q"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/kos/nlav11935/streamlake-website";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN_OUTER =
  'https://a733d38d429c4a67b576e7a579a817a8@sentry.kuaishou.com/2595';

const SENTRY_DSN_INNER =
  'https://a733d38d429c4a67b576e7a579a817a8@sentry-web.corp.kuaishou.com/2595';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production' ? SENTRY_DSN_OUTER : SENTRY_DSN_INNER,
  tracesSampleRate: 1.0,
  environment: process.env.NEXT_PUBLIC_NODE_ENV,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
