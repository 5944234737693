import '../styles/globals.scss';
import Layout from '../components/layout';
import { LayoutStateProvider } from '../context/layout';
import { WebPSupportsProvider } from '../context/webp-support';
import { UserAgentProvider } from '../context/user-agent';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import Router, { useRouter } from 'next/router';
import Head from '@/components/Head';
import tdkConfig from '@/utils/tdk';
import getConfig from 'next/config';
import GlobalPortal from '@/components/global-portal';
import Radar from '@ks-radar/radar/vue-spa';
import theme from '@/utils/theme';
import { ConfigProvider } from 'antd';

const { publicRuntimeConfig } = getConfig();

if (typeof window !== 'undefined') {
  let radar: Radar;
  let fmp;
  import('../utils/weblogger').then(({ radar: r }) => {
    radar = r;
  });
  document.addEventListener('DOMContentLoaded', () => {
    fmp = Date.now();
  });
  window.addEventListener('load', () => {
    radar.fmp({
      currentRoutePath: Router.router.pathname,
      timestamp: fmp,
    });
    radar.sfmp({
      currentRoutePath: Router.router.pathname,
      timestamp: fmp,
    });
  });
  Router.events.on('routeChangeComplete', () => {
    radar.fmp({ currentRoutePath: Router.router.pathname });
    radar.sfmp({ currentRoutePath: Router.router.pathname });
    console.log('fmp collector', radar.spaFMPCollector);
  });
}
function MyApp({ Component, pageProps, userInfo, router }) {
  const config = tdkConfig?.[router.pathname];
  const [uaInfo, setUaInfo] = useState<{
    deviceType?: string;
    browserName?: string;
  }>();
  // NOTE: 如果是测试环境 那么恢复下面这段 就可以在移动端出现 vConsole
  useEffect(() => {
    const info = (() => {
      const cookies = document.cookie.split('; ');
      const info: {
        deviceType?: string;
        browserName?: string;
      } = {};
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === 'deviceType') {
          info['deviceType'] = decodeURIComponent(cookie[1]);
        }
        if (cookie[0] === 'browserName') {
          info['browserName'] = decodeURIComponent(cookie[1]);
        }
      }
      return info;
    })();
    setUaInfo(info);
    if (
      ['development', 'test'].includes(process.env.NEXT_PUBLIC_NODE_ENV) &&
      info.deviceType === 'mobile'
    ) {
      import('vconsole').then(
        (VConsole) => new VConsole.default({ theme: 'dark' })
      );
    }
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <LayoutStateProvider>
        <WebPSupportsProvider>
          <Head
            title={config?.title}
            customTitle={config?.customTitle}
            description={config?.description}
            key={config?.description}
            canonicalUrl={`${publicRuntimeConfig.appDomain}${router.pathname}`}
          >
            <link
              rel="preconnect"
              href={process.env.NEXT_PUBLIC_CDN_HOST}
            ></link>
          </Head>
          {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
            <Script
              src="https://hm.baidu.com/hm.js?3138dd56d7b63cd1e432c58676f5a1dd"
              strategy="lazyOnload"
            />
          )}
          <UserAgentProvider
            value={{
              isMobile: uaInfo?.deviceType === 'mobile',
              browserName: uaInfo?.browserName,
            }}
          >
            <Layout>
              <GlobalPortal />
              <Component {...pageProps} />
            </Layout>
          </UserAgentProvider>
        </WebPSupportsProvider>
      </LayoutStateProvider>
    </ConfigProvider>
  );
}

export default MyApp;
