import request from '../../../utils/request/next-request';

export const logout = async () => {
  await request({
    url: `${process.env.NEXT_PUBLIC_CONSOLE_AUTH_URL}/common/logout`,
    method: 'get',
    withCredentials: true,
    credentials: 'include',
    data: {},
  });
  window.location.reload();
};

export const EXPENSE_CENTER = `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/expense-center/overview`;
export const ACCOUNT_INFO = `${process.env.NEXT_PUBLIC_CONSOLE_BASE_URL}/account/user-info`;
