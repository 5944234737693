import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface IProps extends CustomIconComponentProps {
  component: any;
}
/**
 * antd 自定义svg icon，这个组价可以实现hover改变颜色等操作
 * @param param0
 * @returns
 */
const SvgIcon = ({ component, ...props }: IProps) => {
  return <Icon component={component} {...props} />;
};
export default SvgIcon;
