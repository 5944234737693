interface MetaInfo {
  title: string;
  customTitle?: string;
  description?: string;
  keywords?: string;
}

type RouterItem = MetaInfo & { link?: string };

export type RouterConfig = RouterItem & {
  key?: string; // 一级菜单kkey
  link?: string; // 跳转链接（无popover）
  icon?: string; // 在标题后的图标
  children?: ListMenu | TreeMenu; // popover
};

type ListMenu = RouterItem[];

type TreeMenu = (RouterItem & {
  subTitle?: string[]; // 二级菜单分类小标腿
  blockLayout?: ('line' | 'col')[]; // 二级菜单分类布局类型，横向与纵向， line代表区块内容横排整行；col代表block占据一半宽度
  items?: Array<
    RouterItem & {
      type?: number; // 映射subTitle
    }
  >;
})[];

export const HOME_PAGE_CONFIG = {
  title: '首页',
  link: '/',
  description:
    '全球领先的音视频及AI服务实践者与提供方，基于前沿的音视频技术和人工智能算法、稳定的云基础设施保障、深度的业务理解与实践，为客户提供全链路智能化音视频解决方案',
  customTitle: 'StreamLake-视频化升级助推器',
  keywords: 'StreamLake,溪流湖,快手toB视频云,视频AI,音视频解决方案',
};

export const HEADER_CONFIG: RouterConfig[] = [
  // {
  //   title: '最新活动',
  //   customTitle: '云启共生 · 水润万象',
  //   key: 'activity',
  //   icon: '/img/hot.svg',
  //   link: '/activity/publish',
  // },
  {
    title: '活动资讯',
    key: 'activity',
    link: '/activity',
    icon: 'NEW',
  },
  {
    title: '产品',
    key: 'products',
    children: [
      {
        title: 'StreamLake-Video',
        items: [
          {
            title: '点播云',
            link: '/product/vod',
            keywords:
              '点播云,云点播,点播,vod,点播服务,点播解决方案,点播平台,音视频点播,直播回看,播放器',
            description:
              'StreamLake点播云是基于数亿级DAU产品实践经验沉淀，以点播体验优化为目标，提供集视频上传、存储、媒体处理、分发加速、播放、数据驱动于一体的精细化音视频服务，帮助您快速构建点播业务。同时包含视频分析、画质增强、内容理解等增值能力，提供智能化的完整点播解决方案。',
          },
          {
            title: '直播云',
            link: '/product/live',
            keywords:
              '直播云,直播,视频直播,直播解决方案,互动直播,直播接入,直播转码,拉流',
            description:
              'StreamLake云直播依托快手亿级日活直播平台技术沉淀，为开发者提供专业、稳定的直播推流、转码、分发及播放服务，全面满足低延迟、低码高清、超高画质、大并发访问量的要求，适用于教育、游戏、媒体、电商、社区等场景，帮助客户快速构建直播能力',
          },
          {
            title: '媒体处理',
            link: '/product/mps',
            keywords:
              '媒体处理,智能媒体处理,转码,窄带高清,低码高清,视频服务,SL200',
            description:
              'StreamLake媒体处理（MPS，Media Process Service）针对海量多媒体内容提供高质量、高效率、低成本的处理服务，如音视频转码、视频修复与增强、画质检测、智能生产、音频处理等。通过丰富的原子能力与场景化的解决方案帮助客户提升多媒体处理环节的质量与效率。',
          },
          {
            title: '实时音视频',
            link: '/product/rtc',
            keywords:
              '实时音视频,RTC,实时通信,互动直播,低延时,直播连麦,语聊房,K歌,视频通话,在线教育',
            description:
              'StreamLake实时音视频服务提供覆盖全球的高质量、低延迟实时音视频通信能力，广泛应用于社交娱乐、在线教育、云渲染、在线办公等多种场景。可帮助开发者快速搭建语音通话、视频通话、互动直播、在线KTV、语聊电台、视频会议等解决方案',
          },
          {
            title: '音视频SDK',
            link: '/product/sdk',
            keywords:
              '合成, 上传, 魔法表情, 贴纸, 美体, 美装, AR, 美颜, 滤镜, 相册, 草稿, Animoji, 直播, 点播, 全链路, 多终端, 硬编, 剪辑, 转场, 涂鸦, 画布, 一键出片, 智能模版, 画质增强, 点播, 短视频, 中视频, 长视频',
            description:
              'StreamLake推出一系列 SDK，覆盖点播、直播链路中的拍摄、编辑、合成、上传、推流到播放等环节，同时融合智能美化特效、智能语音及一键成片等AI能力，功能模块按需集成，提供高效灵活的接入方案',
          },
          {
            title: '快手云剪',
            link: '/product/onvideo',
            keywords:
              'onvideo, 快手, 云剪辑，云剪, 在线剪辑, 在线编辑, 视频编辑软件, 视频制作, 视频发布, 直播拆条, 直播切条, 直播剪辑, 视频封面, 视频抠像, 数据视频, 视频去抖, 视频增强',
            description:
              '快手云剪一款基于浏览器的PC端视频剪辑工具，提供版权素材、云端剪辑、直播剪辑、图文转视频、云端渲染、云端资产管理、多人协作、媒资管理共享、智能抠像、画质增强、智能去抖多款智能AI创作工具等一站式云端视频创作能力。主要面向电脑端多人协同视频生产场景，创作者无需下载任何软件，登录快手云剪网页编辑器即可快速制作出高质量短视频。自媒体从业者、专业剪辑团队、广告营销代理机构、企业品宣人员、媒体机构、电商主、剪辑爱好者都能使用快手云剪制作出更专业的视频效果',
          },
          // {
          //   title: '快手闪电',
          //   link: '',
          // },
          {
            title: '快手极目',
            link: '/product/jimu',
            keywords: '画质评测, 视频质量分析',
          },
          // {
          //   title: '智能音频3A',
          //   link: '/product/audio-3a',
          // },
          {
            title: 'SoundMatrix A10',
            link: '/product/sound-matrix',
            keywords:
              '智能会议, 会议硬件产品, 会议麦克风, AI智能降噪, AI回声消除, AI混响抑制, 顶级定制音腔, 超远拾音, 全双工技术, 即插即用, 快手自研技术, 多平台兼容',
            description:
              'SoundMatrixA10是一款兼容Windows、Mac、Android、IOS多种系统，兼容闪电会议、腾讯会议等多种会议软件的智能麦克风音箱。基于快手自研音视频技术，经过多款产品打磨沉淀和数亿用户反馈迭代，AI智能降噪、回声消除、自动增益技术在行业领域全面领先，有效消除背景噪音，还原高清人声，为专业的会议打造极致的视听体验',
          },
          {
            title: 'SoundMatrix V10',
            link: '/product/sound-matrix-v10',
          },
          // {
          //   title: 'SoundMatrix V10',
          //   link: '',
          // },
          {
            title: '六自由度',
            link: '/product/6dof',
          },
          // {
          //   title: 'SL 200',
          //   link: '',
          // },
          {
            title: '硬件编码器',
            link: '/product/live-encoder',
            keywords:
              '编码器,解码器,SL200,直播编码,极速高清,编码,芯片,硬件编码器',
            description:
              'StreamLake直播硬件编码器集成了快手自研SL200编码卡及质臻影音算法，利用快手领先的视频编解码及增强和修复算法，帮助客户提升多媒体处理环节的质量与效率，可应⽤于IPTV、OTT、互联网视频直播等多个领域的超高清直播平台建设及视频压缩编码',
          },
        ],
      },
      {
        title: 'StreamLake-AI',
        subTitle: ['智能视频创作', '智能视频理解', 'AI算法', '数字人及XR'],
        blockLayout: ['line', 'line'], // 指定布局类型，与subTitle索引对照，可自由组合
        items: [
          {
            title: '智能特效',
            link: '/product/effect',
            type: 0,
            keywords:
              '智能特效,AR特效,贴纸,道具,特效道具,2D特效,3D特效,互动特效',
            description:
              'StreamLake智能特效是基于自研的CV、CG、AR/MR/XR等技术，实现短视频、直播拍摄场景下2D贴纸、3D头套、游戏、AR等类型的特效效果，海量特效素材可选，广泛应用于营销、文旅宣传与社交软件等场景',
          },
          {
            title: '人像美化',
            link: '/product/beautification',
            type: 0,
            keywords: '人像美化,美颜,美型,微整形,美体,人脸试妆,美妆',
            description:
              'StreamLake人像美化支持PC端、移动端及服务端跨平台兼容的人像美化功能，涵盖美颜、2D/3D美妆、滤镜、精细化面部重塑及五官、体态精准塑形，同时自适应复杂光照环境，实现细腻、真实的美化效果，可调节强度参数，满足个性化需求',
          },
          {
            title: '智能创作',
            link: '/product/AI-creation',
            type: 0,
            keywords:
              '智能创作, 智能文本生成, 智能视频生成, 智能音乐生成, 字母生成, 自动字幕, 文本转视频, 智能歌唱, AI歌手, AI唱歌, 视频译制, 评论生成, 智能唱歌, 视频浓缩, 视频标题浓缩, 虚拟人物演唱',
            description:
              '智能创作平台为用户提供丰富的AI创作工具，降低了内容创作门槛和成本，为内容创作赋能，提升内容生产和运营效率，可被广泛应用于各类视频、直播、音乐场景',
          },
          {
            title: '智能大屏',
            link: '/product/big-screen',
            type: 0,
          },
          {
            title: '智能标签',
            link: '/product/tag',
            type: 1,
            keywords:
              '内容理解,视频理解,视频结构化,智能标签,视频类目,视频主题,多模态内容理解,图片理解',
            description:
              'StreamLake智能标签基于自研多模态AI技术，多角度多粒度结构化理解视频内容，生成视频兴趣和细粒度元素标签，为智能创作、审核、推荐和搜索提供理解信号',
          },
          {
            title: '智能审核',
            link: '/product/smart-audit',
            type: 1,
            keywords:
              '内容审核,视频审核,敏感视频审核,图片审核,音频审核,涉政审核,涉暴审核,涉黄审核',
            description:
              'StreamLake智能审核基于自研多模态AI技术，自动识别各类媒体资源中的违规和低质内容，覆盖内容安全和内容质量等审核场景，提升审核效率、节省人力成本',
          },
          {
            title: '质量分析',
            link: '/product/quality-analysis',
            type: 1,
            keywords:
              '质量分析, 视频质量分析, 视频分析, 清晰度, 美感度, 美观度, 牛皮癣, 劣质, 模糊, 马赛克, 纯色, 封面, 文字, 三段式, 画面不适, 无意义, 质量, 诱导, 广告, 优质',
            description:
              '视频质量分析通过提供质量分级和质量标签，可对内容观感、内容基本价值、内容深度价值等进行分析，从多个维度对短视频及直播内容进行质量刻画',
          },
          {
            title: '原创保护',
            link: '/product/originality-protection',
            type: 1,
          },
          {
            title: '语音识别',
            link: '/product/sound-detected',
            type: 2,
          },
          {
            title: '人像分割',
            link: '/product/portrait-segmentation',
            type: 2,
          },
          {
            title: '虚拟人',
            link: '/product/digital-human',
            type: 3,
            keywords:
              '虚拟人,数字人,2D虚拟人,3D虚拟人,虚拟主播,虚拟形象,真人数字模型,快手数字人直播',
            description:
              'StreamLake虚拟人基于自研的虚拟人全链路AI技术，提供集虚拟人建模、绑定、驱动、实时渲染、互动玩法、直播运营于一体的一站式虚拟人解决方案。帮助客户打造虚拟形象和场景对外传播，为直播电商、虚拟拍摄、智能文娱等行业带来新机遇',
          },
        ],
      },
    ],
  },
  {
    title: '解决方案',
    key: 'solutions',
    children: [
      {
        title: '泛娱乐行业',
        link: '/solutions/entertainment',
      },
      {
        title: '广电传媒行业',
        link: '/solutions/radio-tv',
      },
      {
        title: '语聊/K歌行业',
        link: '/solutions/audio-call',
      },
      {
        title: '电商行业',
        link: '/solutions/e-commerce',
      },
    ],
  },
  {
    title: '客户价值',
    link: '/values',
  },
  {
    title: '文档',
    link: '/document',
  },
  {
    title: '支持与帮助',
    link: '/support/support-help',
  },
];

export const FOOTER_CONFIG = [
  {
    title: '关于我们',
    items: [
      {
        title: '了解我们',
        link: '/values',
      },
      {
        title: '文档中心',
        link: '/document',
      },
    ],
  },
  {
    title: '支持与服务',
    items: [
      {
        title: '服务咨询',
        link: '/support/support-help',
      },
      {
        title: '意见与反馈',
        link: '/support/support-help',
      },
    ],
  },
  {
    title: '法律支持',
    items: [
      {
        title: '服务协议',
        link: '/policy/StreamLake用户服务协议.html',
      },
      {
        title: '隐私政策',
        link: '/policy/StreamLake隐私政策.html',
      },
    ],
  },
  {
    title: '联系我们',
    items: [
      {
        title: '业务咨询',
        link: '/form/consult/business-footer',
      },
      {
        title: '市场合作',
        link: '/form/consult/cooperation-footer',
      },
      {
        title: '电话与邮箱',
        link: '/support/support-help',
      },
    ],
  },
  {
    title: '友情链接',
    items: [
      {
        title: '快手',
        link: 'https://www.kuaishou.com',
      },
      {
        title: 'Acfun',
        link: 'https://www.acfun.cn',
      },
      {
        title: '快手云剪',
        link: 'https://onvideo.kuaishou.com/',
      },
      {
        title: 'AI开放平台',
        link: 'https://ai.kuaishou.com',
      },
    ],
  },
];
